(function() {
    angular.module('EntrakV5').controller('distributorDetailController', distributorDetailController);

    function distributorDetailController($scope, $rootScope, KEY, Service, Api, $state, $timeout, $stateParams) {
        console.log('distributorDetailController');

        //$stateParams.distributorId
        var caller = Api.createApiCaller();
        $scope.btnStatus = {};

        $rootScope.hideTabs = true;

        $scope.distributorAccounts = null;

        //init breadcrumbs
        $scope.steps = [{
           name: Service.translate("distributor.distributorTab"),
            navigate: function(){
                $state.go("distributor");
            }
        }, { name: '-' }];

    /* first load */
        $rootScope.loadingPage = 1;
        caller.call(Api.getLandlord($stateParams.distributorId)).then(function(res){
            $scope.steps[1].name = res.name;
            $scope.tenants = [];
            res.buildings.forEach(function(b){
                b.tenants.forEach(function(t){
                    t.buildingName = b.name;
                    t.city = b.city.city;
                    $scope.tenants.push(t);
                });
            });
            $scope.distributorAccounts = res.users;
            $rootScope.loadingPage--;
        }, function(err){
            if (err === KEY.ignore)
                return;
            $rootScope.loadingPage--;
            alert(Service.translate("error.generalGetDataFail"));
        });
    /* first load */

        $scope.$on('$destroy', function() {
            console.log("distributorDetailController destroy");
            caller.cancel();
        });
    }
})();
